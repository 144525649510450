import React from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

const StarsRow = ({ rating }) => {
  const filledStars = Array.from({ length: Math.floor(rating) }, (_, index) => (
    <AiFillStar key={index} className="text-yellow-500" />
  ));

  const remainingStars = Array.from({ length: 5 - Math.floor(rating) }, (_, index) => (
    <AiOutlineStar key={index} className="text-yellow-500" />
  ));

  return (
    <div className="flex items-center pb-2">
      {filledStars}
      {remainingStars}
    </div>
  );
};

export default StarsRow;
