import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/footer";
import Hero from "../components/landing_pages/hero";
import Ribbon from "../components/landing_pages/ribbon";
import LeaveBehind from "../components/landing_pages/leavebehind";
import ServiceAreas from "../components/landing_pages/serviceareas";
import HowItWorks from "../components/landing_pages/howitworks";
import TimeNot from "../components/landing_pages/timenot";
import Marquee from "../components/landing_pages/marquee";
import ReviewsCarousel from "../components/landing_pages/reviews";
import About from "../components/landing_pages/about";
import FaqSection from "../components/landing_pages/faq";
import PricingTables from "../components/pricing_tables";

function Landing() {
  return (
    <div className="bg-white">
      <Hero />
      <Ribbon />
      <About />
      <section
        className="pb-8"
        id="vision-mission"
        style={{
          backgroundColor: "#f9f9f9",
          padding: "40px 20px",
          textAlign: "center",
        }}
      >
        <div style={{ maxWidth: "800px", margin: "0 auto" }}>
          <h2 className="text-3xl font-bold text-yellow-950 mb-8 uppercase">
            Our Vision
          </h2>
          <p
            className="pb-8 font-semibold text-slate-700"
            // style={{ fontSize: "1.2em", color: "#555" }}
          >
            To lead the coffee market in promoting health and wellness by
            offering premium, organic coffee powders that nourish the body and
            energize the mind.
          </p>

          <h2 className="text-3xl font-bold text-yellow-950 mb-8 uppercase">
            Our Mission
          </h2>
          <p
            className="pb-8 font-semibold text-slate-700"
            // style={{ fontSize: "1.2em", color: "#555" }}
          >
            To inspire and elevate coffee moments by providing every home with
            the finest, most flavorful coffee powder, crafting unique
            experiences in every cup.
          </p>
        </div>
      </section>

      <PricingTables />
      <TimeNot />
      <Marquee />
      <LeaveBehind />
      {/* <ServiceAreas /> */}
      {/* <HowItWorks /> */}
      <ReviewsCarousel />
      <FaqSection />
      {/* <Footer /> */}
    </div>
  );
}

export default Landing;
